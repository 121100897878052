<template>
<!-- eslint-disable -->
<v-container fluid class="bs-container pa-0">
    <OnboardingNavBar/>
    <div class="main">
        <div class="nav-header">
            <div class="nav-header-icon backIcon">
                <div class="nav-icon" v-on:click="back">
                    <img src="@/assets/svg/nav-back.svg" class="nav-icon-styles" alt="arrow left" />
                </div>
            </div>
            <div class="nav-header-content">
                <div class="text">
                    <div class="legend">Your Sweep <p class="mt-2 legend2"> Preferences</p></div>
                    <div class="subtext">
                        Tell us about your availability and transportation specifications. 
                    </div>
                </div>
            </div>
        </div>
        <div class="main-content">
            <div class="profile-info-section">
                <div class="profile-info-input-section">
                    <div class="profile-info-input-title">
                       <div class="questionLegend">Preferred days and times for gigs?</div>
                       <div class="questionSubtext">
                            Morning extends from 8am-12pm CST, afternoon from 12pm-5pm CST, and evenings from 5pm-10pm CST. 
                       </div>
                    </div>
                    <div class="input-content">
                        <v-row class="sweepDays" >
                            <v-expansion-panels
                              class="day" multiple
                              v-model="panel[i]"
                              v-for="(item, i) in availabilityData"
                              :key="i"
                            >
    <v-col col="8" class="days-list">
      <v-expansion-panel :ref="`day-${item.day}`">
        <v-expansion-panel-header
          @click="(e) => toggleExpansion(item, e)"
        >
          {{days[item.day]}}
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <div class="options-list">
                             <v-checkbox
                                 class="skill-selection-checkbox"
                                v-model="item.morning"
                                label="Morning"
                                hide-details
                                color="secondary1"
                            ></v-checkbox>
                             <v-checkbox
                                class="skill-selection-checkbox"
                                v-model="item.afternoon"
                                label="Afternoon"
                                 hide-details
                                color="secondary1"
                             ></v-checkbox>
                               <v-checkbox
                                class="skill-selection-checkbox"
                               v-model="item.evening"
                               label="Evening"
                                 hide-details
                                color="secondary1"
                           ></v-checkbox>
                        </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
      </v-col>
     
    </v-expansion-panels>         
   

                        </v-row>
                    </div>
                </div>
                <div class="profile-info-input-section">
                    <div class="profile-info-input-title">
                        <div class="questionLegend">How often would you like to sweep with Blended Sense?</div>
                    </div>
                    <div class="profile-info-content">
                        <div class="options-list">
                          <v-radio-group
                          v-model="sweepWithBS"
                hide-details
              >
               <v-list-item two-line v-for="(item, index) in items" :key="index">
        <v-list-item-content style="width:50px;">
          <v-list-item-title >
          <v-radio :value="item.value" color="secondary1">
  <template slot="label">
    <span class="checkboxTitle">
    {{item.text}}
    </span>
  </template>
</v-radio>
            </v-list-item-title>
          <v-list-item-subtitle class="ml-8 hint">{{item.hint}}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
              </v-radio-group>
                        </div>
                        
                    </div>
                </div>
                 <div class="profile-info-input-section">
                    <div class="profile-info-input-title">
                        <div class="questionLegend">What is your mode of transportation?</div>
                    </div>
                    <div class="profile-info-content">
                       <div class="options-list">
                          <v-radio-group
                          v-model="transportation"
                hide-details
              >
               <v-list-item v-for="(item, index) in transportationData" :key="index">
        <v-list-item-content>
          <v-list-item-title >
          <v-radio :value="item.value" color="secondary1">
  <template slot="label">
    <span class="checkboxTitle">
    {{item.text}}
    </span>
  </template>
</v-radio>
            </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
              </v-radio-group>
                        </div>
                        
                    </div>
                </div>
                 <div class="profile-info-input-section">
                    <div class="profile-info-input-title">
                        <div class="questionLegend">Select how many miles you’re willing to travel from your location:</div>
                    </div>
                    <div class="profile-info-content">
                    <div class="options-list">
                          <v-radio-group
                          v-model="travelMiles"
                hide-details
              >
               <v-list-item v-for="(item, index) in miles" :key="index">
        <v-list-item-content>
          <v-list-item-title >
          <v-radio :value="item.value" color="secondary1">
  <template slot="label">
    <span class="checkboxTitle">
    {{item.text}}
    </span>
  </template>
</v-radio>
            </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
              </v-radio-group>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
        <div class="divider2"></div>
        <div class="content-main-actions d-flex justify-end">
            <div class="action-container">
                <div class="continue-btn-wrapper d-flex flex-column">
                  <div class="divider"></div>
                    <v-btn
                        class="continue-btn"
                        depressed
                        @click="() => updatePreferences()"
                    >
                        Submit
                    </v-btn>
                </div>
            </div>
        </div>
    </div>
    
</v-container>

</template>

<script>

import { mapActions, mapGetters } from 'vuex';
import _ from 'lodash';
import { getNextOnboardingStepInfo } from '@/helpers/onboarding';
import OnboardingNavBar from './OnboardingNavBar';

export default {
  name: 'SweepPreferences',
  components: {
    OnboardingNavBar,
  },
  data() {
    return ({
      panel: [[0], [0], [0], [0], [0], [0], [0]],
      items: [{ text: 'Periodically', value: '1', hint: '1-2 times per month' }, { text: 'Regularly', value: '2', hint: '1 time a week' }, { text: 'Frequently', value: '3', hint: '+2 times a week' }],
      days: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
      transportationData: [{ text: 'Vehicle', value: '1' }, { text: 'Public Transportation', value: '2' }, { text: 'Bike', value: '3' }, { text: 'Walking', value: '4' }],
      miles: [{ text: 'Less than 5 miles', value: '1' }, { text: '5- 15 miles', value: '2' }, { text: '16- 25 miles', value: '3' }, { text: 'More than 25 miles', value: '4' }],
      travelMiles: false,
      sweepWithBS: false,
      transportation: false,
      availabilityData: [
        {
          day: '1',
          morning: 1,
          afternoon: 1,
          evening: 1,
        },
        {
          day: '2',
          morning: 1,
          afternoon: 1,
          evening: 1,
        },
        {
          day: '3',
          morning: 1,
          afternoon: 1,
          evening: 1,
        },
        {
          day: '4',
          morning: 1,
          afternoon: 1,
          evening: 1,
        },
        {
          day: '5',
          morning: 1,
          afternoon: 1,
          evening: 1,
        },
        {
          day: '6',
          morning: 1,
          afternoon: 1,
          evening: 1,
        },
        {
          day: '0',
          morning: 1,
          afternoon: 1,
          evening: 1,
        },
      ],
    });
  },
  methods: {
    ...mapActions('onboarding', ['creativeSweepPreferences']),
    ...mapActions('profile', ['getSavedProfile']),
    async updatePreferences() {
      const data = {
        availabilityData: this.availabilityData,
      };
      if (this.travelMiles) {
        data.travelMiles = this.travelMiles;
      }
      if (this.sweepWithBS) {
        data.sweepWithBS = this.sweepWithBS;
      }
      if (this.transportation) {
        data.transportation = this.transportation;
      }
      const response = await this.creativeSweepPreferences(data);
      if (response) {
        const nextStep = getNextOnboardingStepInfo({
          onboardingState: response.nextState,
          role: 'creative',
        });
        if (nextStep.url) {
          this.$router.push({
            path: nextStep.url,
          });
        }
      }
    },
    toggleExpansion(item, e) {
      const refKey = `day-${item.day}`;
      // eslint-disable-next-line
      const [ expansionPanel ] = this.$refs[refKey];
      const availabilityValues = [
        item.morning,
        item.afternoon,
        item.evening,
      ];
      const someAreTruthy = availabilityValues.some((value) => value);
      if (someAreTruthy && expansionPanel.isActive) {
        e.stopPropagation();
        e.preventDefault();
        expansionPanel.toggle();
      }
    },
    back() {
      const skillsets = this.userDetails.profile && _.map(this.userDetails.profile.skillsets, 'name');
      if (skillsets.some((r) => ['field producer'].includes(r.toLowerCase()))) {
        this.$router.push({
          path: '/onboarding/creative/field-producer-details',
        });
      } else if (skillsets.some((r) => ['talent'].includes(r.toLowerCase()))) {
        this.$router.push({
          path: '/onboarding/creative/talent-details',
        });
      } else if (skillsets.some((r) => ['photographer', 'videographer'].includes(r.toLowerCase()))) {
        this.$router.push({
          path: '/onboarding/creative/sweep-details',
        });
      } else {
        this.$router.push({
          path: '/onboarding/creative/language-preference',
        });
      }
    },
  },
  async mounted() {
    await this.getSavedProfile();
    // eslint-disable-next-line
    if ((Number(this.userDetails.onboardingState) < 5) || Number(this.userDetails.onboardingState) > 8) {
      this.$router.push({
        path: '/',
      });
    }
  },
  computed: {
    ...mapGetters('user', ['userDetails']),
  },
};
</script>
<style lang="scss" scoped>
::v-deep.theme--light.v-list-item .v-list-item__subtitle{
  margin-left: 55px !important;
  margin-top: 5px;
}
::v-deep.v-list-item .v-list-item__title{
  max-width: fit-content !important;
}
::v-deep.v-list-item__title{
  overflow:visible;
  margin-left: 23px;
  margin-top: 15px;
  width: 50px;
}
::v-deep.v-radio{
  height: 5px;
}
.bs-container {
    flex-direction: column;
    min-height: 100vh;
    background: white;
}
.questionLegend {
font-family: $fontFamily1;
font-size: 18px;
font-weight: 600;
line-height: 27px;
text-align: left;
color: #262626;
}
.questionSubtext {
font-family: $fontFamily1;
font-size: 16px;
font-weight: 400;
line-height: 24px;
letter-spacing: 0em;
text-align: left;
color: #929292;
}
.main {
    padding: 2rem 4rem;
    width: fit-content;
    margin: auto;
}
.nav-header {
    display: flex;
    // align-items: center;
}
.legend2{
  margin-left:20rem;
  margin-top:-3.7rem !important;
  margin-bottom: -10px;
}
.nav-header-icon {
    margin-right: 4rem;
    margin-top: 1rem;
}
.nav-header .text .legend {
font-family: $fontFamily1;
font-size: 46px;
font-weight: 800;
line-height: 58px;
letter-spacing: 0em;
text-align: left;
padding-bottom: 10px;
}
.nav-header .text .subtext {
    font-family: $fontFamily1;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    color: #262626;
}
.main-content {
    margin-top: 2rem;
    margin-left: 5rem;
    display: flex;
}
.profile-pic-section {
    margin-right: 5rem;
}
.profile-pic-upload-btn {
    border-radius: 2rem;
    border: 2px solid #41E0BA;
    background-color: white;
    padding: 0.5rem 2.5rem !important;
    margin-top: 1rem;
    & > .v-btn__content {
        font-size: 14px !important;
        font-weight: 600 !important;
    }
}
.profile-pic-preview-container {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid #DEDEDE;
}
.profile-info-input-title .legend {
    font-family: $fontFamily1;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    color: #262626;
}
.profile-info-input-section {
    margin-bottom: 4rem;
    margin-top: 1rem;
}
.days-list{
  margin-left:-20px;
}
.skill-selection-checkbox {
    color: $secondary1 !important;
    & > .v-label {
        font-size: 14px !important;
        font-weight: 600 !important;
    }
}
.input-content-title {
    font-family: $fontFamily1;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #262626;
}
.input-content-title {
    // margin-top: 1rem;
}
.input-content-link {
    // margin-top: 0.5rem;
}
.input-content-title .legend {
    font-family: $fontFamily1;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #262626;
}
.actions {
    margin-top: 2rem;
}
.continue-btn-wrapper{
    max-width: 270px;
    .btn-note {
        margin-top: 0.5rem;
        color: #929292;
        font-family: $fontFamily1;
    }
}
.continue-btn {
    width: 100%;
    margin-top:1rem;
    border-radius: 1.5rem;
    height: 44px;
    padding: 0.5rem 2rem !important;
    background: $secondary1 !important;
    color: white;
    .v-btn__content {
    font-size: 16px;
    line-height: 28px;
    }
}
.v-expansion-panels {
    z-index: unset;
    width: 178px
}

.v-expansion-panel {
    border: 1px solid #DEDEDE;
    border-radius: 4px;
}
.v-expansion-panel-header {
background: #FFFFFF;
border-radius: 4px;
padding: 2px 5px;
padding-left: 10px;
font-family: $fontFamily1;
font-size: 18px;
font-weight: 600;
line-height: 27px;
text-align: left;
color: #262626;
padding: 10px;
}
.sweepDays {
  margin: 0px;
  padding-top: 20px;
  width: 75%;
  align-items: 'center';
}
.v-expansion-panel-content {
::v-deep .v-expansion-panel-content__wrap {
    padding-left: 10px !important;
 }
}

.v-expansion-panel--active .v-expansion-panel-header {
  min-height: 0px;
}
.v-input--checkbox ::v-deep {
  .v-label {
font-family: $fontFamily1;
font-size: 14px;
font-weight: 400;
letter-spacing: 0em;
text-align: left;
color: #262626;
  }
  .v-icon {
      font-size: 20px;
  }
}

.v-list-item {
    padding: 0px;
}
.hint {
font-family: $fontFamily1;
font-size: 13px;
font-weight: 400;
line-height: 18px;
letter-spacing: 0rem;
text-align: left;
color: #929292;
}
.checkboxTitle {
font-family: $fontFamily1;
font-size: 14px;
font-weight: 400;
line-height: 21px;
letter-spacing: 0em;
text-align: left;
color: #262626;
}
.v-list-item {
 min-height: 35px ;
}
.v-list-item__content {
  padding:8px;
  margin-left:-32px;
  overflow: visible !important;
}
.profile-info-content {
    padding-top: 15px;
}
.backIcon {
  cursor: pointer;
}
.divider2{
  width:100%;
  margin-bottom: 1rem;
  border-top: 1px solid #dedede;
}
@media screen and (max-width: 600px) {
  .content-main {
   padding-left: 0px;
  }
  .main {
    padding: 2rem;
    width: 100%;
  }
  .nav-header .text .legend {
    font-size: 30px;
    line-height: 28px;
    margin-top: 1rem;
  }
  .legend2{
    margin-top:0.8rem !important;
    margin-left:0rem;
    margin-bottom: 0.8rem !important;
  }
  .nav-header .text .subtext {
    font-size: 16px;
    line-height: 28px;
  }
  .rowDisplay {
    display: block;
  }
  .nav-header-icon {
    margin-right: 1rem;
    margin-top: 1rem;
  }
  .nav-icon-styles{
    width:8px;
    height: 16px;
  }
  .main-content {
    margin-left:0.2rem;
    padding-right: 1rem;
    width:340px;
  }
  .profile-info-section{
    margin-left:1.5rem;
  }
  .continue-btn-wrapper{
    max-width: 100%;
    margin-left:0rem;
  }
  .continue-btn{
    width:335px;
    margin-left:0rem !important;
    margin-right:0rem !important;
  }
  .content-main-actions{
    display: flex;
    justify-content: center !important;
  }
  .divider{
    margin: 0rem 0rem;
    border-top: 1px solid #DEDEDE;
  }
  .divider2{
    display: none;
  }
}
</style>
