<template>
<!-- eslint-disable -->
<v-container fluid class="bs-container pa-0">
    <OnboardingNavBar/>
     <div v-if="loading" class="loader">
      <img
          src="@/assets/svg/theme/bs_logo.gif"
          class="loader_gif"
      />
    </div>
    <div class="main" v-else>
        <div class="nav-header">
            <div class="nav-header-icon">
                <div class="nav-icon backIcon" v-on:click="back">
                    <img src="@/assets/svg/nav-back.svg" class="nav-icon-styles" alt="arrow left" />
                </div>
            </div>
            <div class="nav-header-content">
                <div class="text">
                    <div class="legend">Tell us about yourself</div>
                    <div class="subtitle">
                        This information allows us to better understand who you are as a creative.
                    </div>
                </div>
            </div>
        </div>
        <div class="main-content">
            <div class="profile-pic-section">
                <div class="profile-pic-input-container">
                    <div class="profile-pic-input">
                        <div class="profile-pic-preview-container">
                            <div class="preview">
                                <img :src="profilePreviewImage" alt="profile pic" />
                            </div>
                        </div>
                    </div>
                    <div class="profile-pic-button">
                        <v-btn
                            class="profile-pic-upload-btn"
                            color="#262626"
                            dark
                            large
                            depressed
                            outlined
                            @click="openFileCropper"
                        >
                        Upload Photo
                        </v-btn>
                    </div>
                </div>
            </div>
            <div class="profile-info-section">
                <div class="profile-info-input-section">
                    <div class="profile-info-input-title">
                       <div class="legend mb-5">Birthday</div>
                    </div>
                    <div class="input-content">
                        <v-row class="rowDisplay">
                            <div class="BirthdayField">
                                <div class="input-content-title">
                                   Month<sup>*</sup>  
                                </div>
                                <v-select
                                    :items="months"
                                    outlined
                                    dense
                                    v-model="month"
                                    append-icon="mdi-chevron-down"
                                    class="month-input"
                                    placeholder="Select..."
                                    :error-messages="errors.month"
                                />
                            </div>
                            <div class="BirthdayField">
                                <div class="input-content-title">
                                   Date<sup>*</sup>  
                                </div>
                                <v-select
                                    outlined
                                    dense
                                    v-model="day"
                                    :items="daysInMonth"
                                    class="date-input"
                                    append-icon="mdi-chevron-down"
                                    placeholder="-"
                                    no-data-text="Select a month first"
                                    :error-messages="errors.day"
                                />
                            </div>
                            <div class="BirthdayField">
                                <div class="input-content-title">
                                   Year<sup>*</sup>  
                                </div>
                                <v-select
                                    outlined
                                    dense
                                    :items="years"
                                    v-model="year"
                                    class="year-input"
                                    append-icon="mdi-chevron-down"
                                    placeholder="Select..."
                                    :error-messages="errors.year"
                                />
                            </div>
                        </v-row>
                    </div>
                </div>
                <div class="profile-info-input-section">
                    <div class="profile-info-input-title">
                        <div class="legend pb-0">Location</div>
                        <div class="subtext">Knowing this allows for our team to match you to gigs local to you.</div>
                    </div>
                    <div class="input-content">
                        <v-row class="rowDisplay">
                            <v-col>
                                <div class="input-content-title">
                                   City<sup>*</sup>  
                                </div>
                                <!-- :class="{'errorState v-input--has-state error--text':
                                    errors('location').length}" -->
                                <div
                                class="v-input input-field v-input--is-label-active v-input--is-dirty
                                v-input--dense theme--light v-text-field v-text-field--single-line
                                v-text-field--solo v-text-field--solo-flat v-text-field--is-booted
                                v-text-field--enclosed v-text-field--outlined errorState"
                                
                                >
                                <div class="v-input__control">
                                    <div class="v-input__slot">
                                    <fieldset aria-hidden="true">
                                        <legend><span>&#8203;</span></legend>
                                    </fieldset>
                                    <div class="v-text-field__slot">
                                    <vue-google-autocomplete
                                        id="map"
                                        placeholder=" "
                                        v-on:placechanged="onPlaceChanged"
                                        v-on:inputChange="onAddressInputChanged"
                                        :component-restrictions="null"
                                        :types="['establishment']"
                                        v-model.trim="city"
                                        ref="cityRef"
                                        :fields="['city']"
                                    >
                                    </vue-google-autocomplete>
                                    </div>
                                    </div>
                                    <div class="v-text-field__details" v-if="errors.city">
                                        <div class="v-messages theme--light error--text" role="alert">
                                            <div class="v-messages__wrapper">
                                            <div class="v-messages__message">Please provide valid city</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                </div>

                            </v-col>
                            <v-col class="input-fields1" >
                                <div class="input-content-title">
                                   Zip<sup>*</sup>  
                                </div>
                                <v-text-field
                                    v-model="zipcode"
                                    outlined
                                    dense
                                    class="zipcode-input"
                                    :error-messages="errors.zipcode"
                                ></v-text-field>
                            </v-col>
                            <v-col class="input-fields2">
                                <div class="input-content-title2">
                                   State<sup>*</sup>  
                                </div>
                                <v-select
                                    :items="states"
                                    v-model="state"
                                    item-text="name"
                                    item-value="value"
                                    outlined
                                    dense
                                    class="state-input"
                                    append-icon="mdi-chevron-down"
                                    placeholder="Select..."
                                    :error-messages="errors.state"
                                />
                            </v-col>
                        </v-row>
                    </div>
                </div>
                <div class="profile-info-input-section">
                    <div class="profile-info-input-title pb-2">
                        <div class="legend">Skill Set<sup>*</sup></div>
                        <div class="subtext">
                            Select skills you can offer clients, and help us match the right gigs to you.
                        </div>
                    </div>
                    <div class="profile-info-content pb-3 mt-2">
                        <div class="options-list">
                            <v-checkbox
                                class="skill-selection-checkbox"
                                v-model="skills"
                                value="Photographer"
                                color="secondary1"
                            >
                             <template v-slot:label>
                              <span class="checkboxLabel">Photographer</span>
                             </template>
                            </v-checkbox>
                            <v-checkbox
                                class="skill-selection-checkbox"
                                v-model="skills"
                                value="Videographer"
                                color="secondary1"
                            >
                             <template v-slot:label>
                              <span class="checkboxLabel">Videographer</span>
                             </template>
                            </v-checkbox>
                            <v-checkbox
                                class="skill-selection-checkbox"
                                v-model="skills"
                                value="model"
                                color="secondary1"
                            >
                            <template v-slot:label>
                              <span class="checkboxLabel">Model</span>
                             </template>
                            </v-checkbox>
                            <v-checkbox
                                class="skill-selection-checkbox"
                                v-model="skills"
                                value="actor"
                                color="secondary1"
                            >
                            <template v-slot:label>
                              <span class="checkboxLabel">Actor</span>
                             </template>
                            </v-checkbox>
                            <v-checkbox
                                class="skill-selection-checkbox"
                                v-model="skills"
                                value="Field Producer"
                                color="secondary1"
                            >
                            <template v-slot:label>
                              <span class="checkboxLabel">Field Producer</span>
                             </template>
                            </v-checkbox>
                        </div>
                        <div class="v-text-field__details" v-if="errors.skills">
                            <div class="v-messages theme--light error--text" role="alert">
                                <div class="v-messages__wrapper">
                                <div class="v-messages__message">Skills are required</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="profile-info-input-section">
                    <div class="profile-info-input-title pb-4">
                        <div class="legend">Online Presence</div>
                        <div class="subtext" style="max-width:348px;">
                            Show us where we can see your best work to help determine network eligibility.
                        </div>
                    </div>
                    <div class="input-content mt-2">
                        <div class="input-content-link">
                            <div class="input-content-title">
                                <div class="legend">Personal Website URL<sup>*</sup></div>
                            </div>
                            <v-text-field
                                solo
                                flat
                                v-model="website"
                                outlined
                                dense
                                class=""
                                :error-messages="errors.website"
                            ></v-text-field>
                        </div>
                        <div class="input-content-link">
                            <div class="input-content-title">
                                <div class="legend">Instagram URL</div>
                            </div>
                            <v-text-field
                                v-model="instagram"
                                outlined
                                dense
                                class=""
                                :error-messages="errors.instagram"
                            ></v-text-field>
                        </div>
                        <div class="input-content-link">
                            <div class="input-content-title">
                                <div class="legend">Youtube URL</div>
                            </div>
                            <v-text-field
                                v-model="youtube"
                                outlined
                                dense
                                class=""
                                :error-messages="errors.youtube"
                            ></v-text-field>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="divider2"></div>
        <div class="content-main-actions d-flex justify-end mb-4 mt-0">
            <div class="action-container">
                <div class="continue-btn-wrapper d-flex flex-column">
                  <div class="divider"></div>
                    <v-btn
                        class="continue-btn"
                        depressed
                        @click="onSubmit"
                    >
                        Continue
                    </v-btn>
                </div>
            </div>
        </div>
    </div>
    <HyperCropper ref="hyperCropper" />
</v-container>
</template>

<script>
/* eslint-disable no-extra-boolean-cast */
import moment from 'moment';
import _ from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import VueGoogleAutocomplete from 'vue-google-autocomplete';
import HyperCropper from '@/plugins/HyperCropper/Index';
import usStates from '@/helpers/us_states.json';
import { isValidURL } from '@/helpers/index';
import profileFallbackImage from '@/assets/svg/camera-filled.svg';
import { getNextOnboardingStepInfo } from '@/helpers/onboarding';
import OnboardingNavBar from './OnboardingNavBar';

export default {
  name: 'AboutSelf',
  components: {
    VueGoogleAutocomplete,
    OnboardingNavBar,
    HyperCropper,
  },
  data() {
    return ({
      loading: false,
      profileImage: null,
      skills: [],
      city: '',
      zipcode: '',
      state: '',
      website: '',
      instagram: '',
      youtube: '',
      month: '',
      day: '',
      year: '',
      errors: {},
    });
  },
  computed: {
    ...mapGetters('user', ['userDetails']),
    profilePreviewImage() {
      if (typeof this.profileImage === 'string') {
        return this.profileImage || profileFallbackImage;
      }
      if (this.profileImage) {
        return URL.createObjectURL(this.profileImage);
      }
      return profileFallbackImage;
    },
    states() {
      const data = Object.values(usStates);
      return data.map((state) => (
        {
          name: state,
          value: state,
        }
      ));
    },
    months() {
      return [
        { text: 'January', value: 1 },
        { text: 'February', value: 2 },
        { text: 'March', value: 3 },
        { text: 'April', value: 4 },
        { text: 'May', value: 5 },
        { text: 'June', value: 6 },
        { text: 'July', value: 7 },
        { text: 'August', value: 8 },
        { text: 'September', value: 9 },
        { text: 'October', value: 10 },
        { text: 'November', value: 11 },
        { text: 'December', value: 12 },
      ];
    },
    daysInMonth() {
      const days = [];
      // based on month and year if selected
      if (!!this.month && !!this.year) {
        const maxDays = moment(`${this.year}-${this.month}`, 'YYYY-MM').daysInMonth();
        for (let i = 1; i <= maxDays; i += 1) {
          days.push({ text: i, value: i });
        }
        return days;
      }
      // only month available
      if (!!this.month) {
        const maxDays = moment(`${this.month}`, 'MM').daysInMonth();
        for (let i = 1; i <= maxDays; i += 1) {
          days.push({ text: i, value: i });
        }
        return days;
      }
      return days;
    },
    years() {
      const years = [];
      const currentYear = moment().year();
      for (let i = currentYear; i >= 1950; i -= 1) {
        years.push({ text: i, value: i });
      }
      return years;
    },
  },
  methods: {
    ...mapActions('onboarding', ['saveCreativeAbout']),
    ...mapActions('profile', ['getSavedProfile']),
    async openFileCropper() {
      try {
        const croppedFile = await this.$refs.hyperCropper.open();
        this.profileImage = croppedFile;
      } catch (err) {
        // operation cancelled. do nothing
      }
    },
    getStateFromShortName(shortName) {
      return usStates[shortName];
    },
    back() {
      this.$router.push({
        path: '/onboarding/creative/get-started',
      });
    },
    onAddressInputChanged(event) {
      const [city, state] = event.newVal.split(',');
      this.city = city.trim();
      const target = this.$refs.cityRef;
      target.update(this.city);
      this.state = this.getStateFromShortName(state.trim());
    },
    onPlaceChanged(event, place) {
      const target = this.$refs.cityRef;
      target.update(place.name);
    },
    isValidState() {
      const errors = {};
      if (!this.month) {
        errors.month = 'Please select a month';
      } else {
        delete errors.month;
      }
      if (!this.day) {
        errors.day = 'Please select a day';
      } else {
        delete errors.day;
      }
      if (!this.year) {
        errors.year = 'Please select a year';
      } else {
        delete errors.year;
      }
      if (!this.city) {
        errors.city = 'City is required';
      } else {
        delete errors.city;
      }
      if (!this.zipcode) {
        errors.zipcode = 'Zipcode is required';
      } else if ((/^\d+$/.test(this.zipcode)
              && this.zipcode.length >= 6 && this.zipcode.length <= 10)) {
        errors.zipcode = 'Invalid zipcode';
      } else {
        delete errors.zipcode;
      }
      if (!this.state) {
        errors.state = 'State is required';
      } else {
        delete errors.state;
      }
      if (!this.skills.length) {
        errors.skills = 'Skills are required';
      } else {
        delete errors.skills;
      }
      if (!this.website) {
        errors.website = 'Website is required';
      } else if (this.website && !isValidURL(this.website)) {
        errors.website = 'Invalid URL';
      } else {
        delete errors.website;
      }
      if (this.instagram && !isValidURL(this.instagram)) {
        errors.instagram = 'Invalid URL';
      } else {
        delete errors.instagram;
      }
      if (this.youtube && !isValidURL(this.youtube)) {
        errors.youtube = 'Invalid URL';
      } else {
        delete errors.youtube;
      }
      this.errors = { ...errors };
      return Object.values(this.errors).length === 0;
    },
    async onSubmit() {
      // validate the data
      const isValid = this.isValidState();
      // generate payload
      if (!isValid) return;
      const formData = new FormData();
      formData.append('avatar', this.profileImage);
      formData.append('city', this.city);
      formData.append('zipcode', this.zipcode);
      formData.append('state', this.state);
      formData.append('website', this.website);
      formData.append('instagram', this.instagram || '');
      formData.append('youtube', this.youtube || '');
      // date of birth using moment
      const dateOfBirth = moment(`${this.year}-${this.month}-${this.day}`, 'YYYY-MM-DD');
      formData.append('dateOfBirth', dateOfBirth.format('YYYY-MM-DD'));
      formData.append('skills', JSON.stringify(this.skills));
      this.loading = true;
      const response = await this.saveCreativeAbout(formData);
      this.loading = false;
      if (response) {
        const nextStep = getNextOnboardingStepInfo({
          onboardingState: response.nextState,
          role: 'creative',
        });
        if (nextStep.url) {
          this.$router.push({
            path: nextStep.url,
          });
        }
      }
    },
  },
  async mounted() {
    await this.getSavedProfile();
    // eslint-disable-next-line
    if (Number(this.userDetails.onboardingState) < 4) {
      this.$router.push({
        path: '/',
      });
    }
    this.profileImage = this.userDetails.profilePic;
    this.city = this.userDetails.userLocation && this.userDetails.userLocation.city;
    this.zipcode = this.userDetails.userLocation && this.userDetails.userLocation.zipcode;
    this.state = this.userDetails.userLocation && this.userDetails.userLocation.state;
    this.website = this.userDetails.profile && this.userDetails.profile.website;
    this.instagram = this.userDetails.profile && this.userDetails.profile.instagramUrl;
    this.youtube = this.userDetails.profile && this.userDetails.profile.youtube;
    if (this.userDetails.dateOfBirth) {
      const dt = new Date();
      this.month = dt.getMonth() + 1;
      this.day = dt.getDate();
      this.year = dt.getFullYear();
    }
    const skillsets = this.userDetails.profile && _.map(this.userDetails.profile.skillsets, 'name');
    const tier = this.userDetails.profile && _.map(this.userDetails.profile.payTiers, 'payTier');
    this.skills = [...skillsets, ...tier];
  },
};
</script>

<style lang="scss" scoped>
::v-deep.v-input--selection-controls .v-input__slot > .v-label{
  max-width: 120px !important;
}
::v-deep.theme--dark.v-btn:focus::before{
  border-radius:2rem;
}
::v-deep.theme--dark.v-btn:hover::before{
  border-radius:2rem !important;
}
.loader{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99999;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 100px;
    height: 100px;
  }
}
.date-input {
  width: 80px;
  height: 44px;
}
.BirthdayField{
  padding-left:1rem;
  margin-bottom:2rem;
}
.input-fields1{
 margin-left: -5.5rem;
}
.input-fields2{
  margin-left:-17.5rem;
}
.month-input {
    width: 247px;
    height: 44px;
}
.year-input{
  width: 116px;
  height: 44px;
}
.v-input__slot{
  width:247px;
}
.zipcode-input{
  width:80px;
  height: 44px;
}
.state-input {
    width: fit-content;
}
.input-content-title2{
  margin-left:-0px
}
.input-content-title2{
  margin-left:-0px
}
.checkboxLabel {
  color: #262626;
}
::v-deep.v-input--selection-controls .v-input__slot > .v-label{
  max-width: fit-content !important;
}
.subtitle {
width:813px;
margin-top:10px;
font-family: $fontFamily1;
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 28px;
color: #262626;
}
.subtext {
font-family: $fontFamily1;
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 24px;
color: #929292;
}
.backIcon {
  cursor: pointer;
}
.bs-container {
    flex-direction: column;
    min-height: 100vh;
    background: white;
}
.main {
    margin-top: -4rem;
    min-width: 315px;
    padding: 4rem 4rem;
}
.nav-icon-styles{
  margin-top:10px;
}
.nav-header {
    display: flex;
    margin-left:-5px;
    // align-items: center;
}
.nav-header-icon {
    margin-right: 4rem;
    margin-top: 1rem;
}
.nav-header-content{
  margin-top:15px;
  margin-left:10px;
}
.nav-header .text .legend {
    font-family: $fontFamily2;
    font-style: normal;
    font-weight: 800;
    // font-size: 2rem;
    font-size: 30px;
    line-height: 38px;
}
.nav-header .text .subtext {
    font-family: $fontFamily1;
    font-style: normal;
    font-weight: 400;
    font-size: 1.2rem;
}
.profile-pic-section {
    padding-right:130px;
    margin-left:100px;
}
.profile-pic-upload-btn {
    height: 33px !important;
    max-width: 20%;
    border-radius: 2rem;
    border: 2px solid #41E0BA;
    background-color: white;
    padding: 0.5rem 2.5rem !important;
    font-weight: 700;
    font-size: 14px;
    margin-top: 1.5rem;
    & .v-btn__content {
        font-size: 14px !important;
        font-weight: 600 !important;
        border-radius: 2rem !important;
    }
}
.profile-pic-preview-container {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 4px solid #DEDEDE;
    overflow: hidden;
    margin-top: 1.5rem;
    & .preview img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}
.profile-info-input-title .legend {
    font-family: $fontFamily1;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    color: #262626;
}
.profile-info-input-title .subtext{
  width:650px;
}
.profile-info-input-section {
    margin-bottom: 2rem !important;
}
.skill-selection-checkbox {
    margin-top: 0.5rem;
    color: $secondary1 !important;
    height: 40px;
    & > .v-label {
        font-size: 14px !important;
        font-weight: 600 !important;
    }
}
.input-content-title {
    font-family: $fontFamily1;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #262626;
}
.input-content-title {
    // margin-top: 1rem;
}
.input-content-link {
    // margin-top: 0.5rem;
}
//new one
.input-content-title .legend {
    font-family: $fontFamily1;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #262626;
}
.actions {
    margin-top: 2rem;
}
.continue-btn-wrapper{
    max-width: 270px;
    .btn-note {
        margin-top: 0.5rem;
        color: #929292;
        font-family: $fontFamily1;
    }
}
.continue-btn {
    margin-top: 1.5rem;
    width: 100%;
    border-radius: 1.5rem;
    height: 44px;
    padding: 0.5rem 2rem !important;
    background: $secondary1 !important;
    color: white;
    .v-btn__content {
    font-size: 16px;
    line-height: 28px;
    }
}
.v-input {
  max-width: 500px;
}
.profile-info-section{
  margin-left:25rem;
  margin-top: -14.5rem;
}
.rowDisplay{
  display: flex;
  width: 700px;
}
.input-fields1{
  margin-left:-6rem;
}
.input-fields2{
  margin-left:-18rem;
}
.divider2{
  width: 100%;
  border-top:1px solid #DEDEDE;
}
.rowDisplay{
  display: flex;
  width:700px;
}
@media screen and (min-width: 0px) and (max-width: 1000px) {
  .rowDisplay {
    display: block;
    width:312px;
  }
  .state-input {
    width: fit-content;
  }
  .input-content-title2{
    margin-left:0px;
  }
  .subtitle{
    width:326px;
  }
  .state-input {
    width: fit-content;
  }
  .input-content-title2{
    margin-left:0px;
  }
  .profile-info-input-title .subtext{
    width:330px;
    padding-right: 1rem;
  }
  .main-content {
    display: block;
    padding:-2rem;
  }
  .nav-header .text .subtitle{
    margin-bottom: 1rem;
  }
  .profile-pic-section{
    margin-left: 2rem;
    margin-right: 3rem;
    justify-content: center;
    display: flex;
    padding-right:130px;
    padding-left:142px;
  }
  .nav-header-icon {
    margin-right: 1rem;
    margin-top: 1.5rem;
  }
  .main {
    padding: 0rem;
    margin-top: 1.5rem;
    margin-left: 1.5rem;
    margin-right: 2rem;
  }
  .profile-pic-upload-btn{
    height: 33px !important;
    max-width: 150px !important;
  }
  .nav-header .text .legend {
    font-size: 30px;
    line-height: 28px;
  }
  .nav-header{
    margin-left:-0.5rem;
  }
  .profile-info-section {
    padding: 30px;
    margin-left: 0rem;
    margin-top: 0rem;
  }
  .subtext {
    width:342px;
    font-weight: 400;
    font-size: 16px;
    color: #929292;
  }
  .continue-btn-wrapper{
    max-width: 100%;
    margin-left:0rem;
    margin-right: 0rem;
  }
  .continue-btn{
    width:335px;
    margin-left:0rem !important;
    margin-right:0rem !important;
  }
  .content-main-actions{
    display: flex;
    justify-content: center !important;
  }
  .BirthdayField{
    margin-top:-1rem;
  }
 .input-fields1{
  margin-left:0rem;
  }
  .input-fields2{
  margin-left:0rem;
  }
  .nav-icon-styles{
    width:15px;
    height: 19px;
    margin-top:1px;
    margin-left: -0.5rem;
  }
  .divider{
    border-top: 1px solid #DEDEDE;
    width:100%;
  }
  .divider2{
    display: none;
  }
}
</style>
