<template>
    <!-- eslint-disable -->
    <v-container fluid class="bs-container pa-0">
        <OnboardingNavBar/>
        <div class="main">
            <div class="nav-header">
                <div class="nav-header-icon backIcon">
                    <div class="nav-icon" v-on:click="back">
                        <img src="@/assets/svg/nav-back.svg" class="nav-icon-styles" alt="arrow left" />
                    </div>
                </div>
                <div class="nav-header-content">
                    <div class="text">
                        <div class="legend">Photo & Video Details</div>
                        <div class="subtext mt-2">
                            What tools do you use to capture. Only include equipment you currently own and use.
                        </div>
                    </div>
                </div>
            </div>
            <div class="main-content">
                <div class="equipment-details-section mt-8">
                    <div class="section-title">Equipment Details</div>
                        <v-row style="max-width:1000px" class="rowDisplay">
                            <v-col cols="6" class="equipment-col">
                                <div class="section-input-title">Camera<sup>*</sup></div>
                                <AutoCompleteField
                                    :items="filters.cameraEquipments"
                                    multiple
                                    outlined
                                    chips
                                    hide-details
                                    v-model="cameraEquipments"
                                    append-icon="mdi-chevron-down"
                                    class="equipment-select"
                                    placeholder="Select..."
                                    item-text="name"
                                    item-value="id"
                                    return-object
                                    :error-messages="errors.cameraEquipments"
                                />
                                <div class="v-text-field__details" v-if="errors.cameraEquipments">
                            <div class="v-messages theme--light error--text" role="alert">
                                <div class="v-messages__wrapper">
                                <div class="v-messages__message">Camera Equipments are required</div>
                                </div>
                            </div>
                            </div>
                            </v-col>
                            <v-col cols="6" class="equipment-col">
                                <div class="section-input-title">Lens<sup>*</sup></div>
                                <AutoCompleteField
                                    v-model="lensEquipments"
                                    :items="filters.lensEquipments"
                                    chips
                                    multiple
                                    outlined
                                    hide-details
                                    append-icon="mdi-chevron-down"
                                    class="equipment-select"
                                    placeholder="Select..."
                                    item-text="name"
                                    item-value="id"
                                    return-object
                                    :error-messages="errors.lensEquipments"
                                />
                                 <div class="v-text-field__details" v-if="errors.lensEquipments">
                            <div class="v-messages theme--light error--text" role="alert">
                                <div class="v-messages__wrapper">
                                <div class="v-messages__message">Lens Equipments are required</div>
                                </div>
                            </div>
                            </div>
                            </v-col>
                            <v-col cols="6" class="equipment-col">
                                <div class="section-input-title">Lights</div>
                                <AutoCompleteField
                                    v-model="light"
                                    :items="filters.light"
                                    chips
                                    multiple
                                    outlined
                                    hide-details
                                    append-icon="mdi-chevron-down"
                                    class="equipment-select"
                                    placeholder="Select..."
                                    item-text="name"
                                    item-value="id"
                                    return-object
                                />
                            </v-col>
                            <v-col cols="6" class="equipment-col">
                                <div class="section-input-title">Audio</div>
                                <AutoCompleteField
                                    v-model="audios"
                                    :items="filters.audios"
                                    chips
                                    multiple
                                    outlined
                                    hide-details
                                    append-icon="mdi-chevron-down"
                                    class="equipment-select"
                                    placeholder="Select..."
                                    item-text="name"
                                    item-value="id"
                                    return-object
                                />
                            </v-col>
                            <v-col cols="6" class="equipment-col">
                                <div class="section-input-title">Stabilizers</div>
                                <AutoCompleteField
                                    v-model="stabilizer"
                                    :items="filters.stabilizer"
                                    chips  
                                    multiple
                                    outlined
                                    hide-details
                                    append-icon="mdi-chevron-down"
                                    class="equipment-select"
                                    placeholder="Select..."
                                    item-text="name"
                                    item-value="id"
                                    return-object
                                />
                            </v-col>
                            <v-col cols="6" class="equipment-col">
                                <div class="section-input-title">Accessories</div>
                                <AutoCompleteField
                                    v-model="accessories"
                                    :items="filters.accessories"
                                    chips
                                    multiple
                                    outlined
                                    hide-details
                                    append-icon="mdi-chevron-down"
                                    class="equipment-select"
                                    placeholder="Select..."
                                    item-text="name"
                                    item-value="id"
                                    return-object
                                />
                            </v-col>
                        </v-row>
                </div>
                <div class="proficient-details-section mt-4">
                    <div class="section-title mb-5">Select what you’re proficient in capturing: <sup>*</sup></div>
                    <div class="proficiency-list">
                            <v-checkbox
                                v-model="proficiencies"
                                label="Live Events"
                                value="Live Events"
                                hide-details
                                class="proficiency-checkbox"
                                color="secondary1"
                            >
                            <template v-slot:label>
                              <span class="checkboxLabel">Live Events</span>
                             </template>
                            </v-checkbox>
                            <v-checkbox
                                v-model="proficiencies"
                                label="Lifestyle"
                                value="Lifestyle"
                                hide-details
                                class="proficiency-checkbox"
                                color="secondary1"
                            >
                            <template v-slot:label>
                              <span class="checkboxLabel">Lifestyle</span>
                             </template>
                            </v-checkbox>
                            <v-checkbox
                                v-model="proficiencies"
                                label="Real Estate"
                                value="Real Estate"
                                hide-details
                                class="proficiency-checkbox"
                                color="secondary1"
                            >
                            <template v-slot:label>
                              <span class="checkboxLabel">Real Estate</span>
                             </template>
                            </v-checkbox>
                            <v-checkbox
                                v-model="proficiencies"
                                label="Interviews"
                                value="Interviews"
                                hide-details
                                class="proficiency-checkbox"
                                color="secondary1"
                            >
                            <template v-slot:label>
                              <span class="checkboxLabel">Interviews</span>
                             </template>
                            </v-checkbox>
                            <v-checkbox
                                v-model="proficiencies"
                                label="Narrative"
                                value="Narrative"
                                hide-details
                                class="proficiency-checkbox"
                                color="secondary1"
                            >
                            <template v-slot:label>
                              <span class="checkboxLabel">Narrative</span>
                             </template>
                            </v-checkbox>
                            <v-checkbox
                                v-model="proficiencies"
                                label="Commercial"
                                value="Commercial"
                                hide-details
                                class="proficiency-checkbox"
                                color="secondary1"
                            >
                            <template v-slot:label>
                              <span class="checkboxLabel">Commercial</span>
                             </template>
                            </v-checkbox>
                            <v-checkbox
                                v-model="proficiencies"
                                label="Cinematic"
                                value="Cinematic"
                                hide-details
                                class="proficiency-checkbox"
                                color="secondary1"
                            >
                            <template v-slot:label>
                              <span class="checkboxLabel">Cinematic</span>
                             </template>
                            
                            </v-checkbox>
                        <div class="v-text-field__details" v-if="errors.proficiencies">
                            <div class="v-messages theme--light error--text" role="alert">
                                <div class="v-messages__wrapper">
                                <div class="v-messages__message">Select proficiencies</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="divider2"></div>
            <div class="content-main-actions d-flex justify-end">
                <div class="action-container">
                    <div class="continue-btn-wrapper d-flex flex-column">
                      <div class="bs-divider"></div>
                         <v-btn
                        class="continue-btn"
                        depressed
                        @click="submit"
                    >
                        Continue
                    </v-btn>
                    </div>
                </div>
            </div>
        </div>
    </v-container>
</template>

<script>

import { mapActions, mapGetters } from 'vuex';
import _ from 'lodash';
import AutoCompleteField from '@/components/common/AutoCompleteField';
import { getNextOnboardingStepInfo } from '@/helpers/onboarding';
import OnboardingNavBar from './OnboardingNavBar';

export default {
  name: 'SweepDetails',
  components: {
    OnboardingNavBar,
    AutoCompleteField,
  },
  data() {
    return ({
      proficiencies: [],
      stabilizer: [],
      accessories: [],
      cameraEquipments: [],
      lensEquipments: [],
      light: [],
      audios: [],
      errors: {},
    });
  },
  methods: {
    ...mapActions('profile', ['getCreativeOptions']),
    ...mapActions('onboarding', ['saveCameraPreferences']),
    ...mapActions('profile', ['getSavedProfile']),
    isValidState() {
      const errors = {};
      if (!this.cameraEquipments.length) {
        errors.cameraEquipments = 'Camera Equipment is required';
      } else {
        delete errors.cameraEquipments;
      }
      if (!this.lensEquipments.length) {
        errors.lensEquipments = 'lens Equipment is required';
      } else {
        delete errors.skills;
      }
      if (!this.proficiencies.length) {
        errors.proficiencies = 'proficiency is required';
      } else {
        delete errors.proficiencies;
      }
      this.errors = { ...errors };
      return Object.values(this.errors).length === 0;
    },
    back() {
      this.$router.push({
        path: '/onboarding/creative/language-preference',
      });
    },
    async submit() {
      const isValid = this.isValidState();
      // generate payload
      if (!isValid) return;
      const formData = {};
      formData.proficiencies = this.proficiencies;
      formData.stabilizer = this.stabilizer;
      formData.accessories = this.accessories;
      formData.cameraEquipments = this.cameraEquipments;
      formData.lensEquipments = this.lensEquipments;
      formData.lights = this.light;
      formData.audios = this.audios;
      this.loading = true;
      const response = await this.saveCameraPreferences(formData);
      this.loading = false;
      if (response) {
        const nextStep = getNextOnboardingStepInfo({
          onboardingState: response.nextState,
          role: 'creative',
        });
        if (nextStep.url) {
          this.$router.push({
            path: nextStep.url,
          });
        }
      }
    },
  },
  async mounted() {
    await this.getSavedProfile();
    // eslint-disable-next-line
    const skillsets = this.userDetails.profile && _.map(this.userDetails.profile.payTiers, ( skill )=> {
      if (skill.skillSetName === 'Talent') {
        return skill.payTier;
      }
      return skill.skillSetName;
    });
    if (Number(this.userDetails.onboardingState) < 6 || !skillsets.some((r) => ['photographer', 'videographer'].includes(r.toLowerCase()))) {
      this.$router.push({
        path: '/',
      });
    }
    await this.getCreativeOptions();
    this.proficiencies = _.get(this.userDetails, 'profile.cameraProficient', '').split(';');
    this.cameraEquipments = _.get(this.userDetails, 'profile.cameraEquipments', []);
    this.stabilizer = _.get(this.userDetails, 'profile.stabilizer', []);
    this.accessories = _.get(this.userDetails, 'profile.accessories', []);
    this.lensEquipments = _.get(this.userDetails, 'profile.lensEquipments', []);
    this.light = _.get(this.userDetails, 'profile.light', []);
    this.audios = _.get(this.userDetails, 'profile.audio', []);
  },
  computed: {
    ...mapGetters('profile', { filters: 'creativePreferences' }),
    ...mapGetters('user', ['userDetails']),
  },
};
</script>

<style lang="scss" scoped>
::v-deep.v-input .v-label {
  max-width: 90px !important;
}
.bs-container {
    flex-direction: column;
    min-height: 100vh;
    background: white;
}
.main {
    padding: 2rem 4rem;
}
.nav-header {
    display: flex;
    // align-items: center;
}
.nav-header-content{
  margin-top:15px;
}
.nav-header-icon {
    margin-right: 4rem;
    margin-top: 1rem;
}
.nav-header .text .legend {
    font-family: $fontFamily2;
    font-style: normal;
    font-weight: 800;
    // font-size: 2rem;
    font-size: 46px;
}
.nav-header .text .subtext {
    font-family: $fontFamily1;
    font-style: normal;
    font-weight: 400;
    font-size: 1.2rem;
}
.main-content {
    margin-left: 4.5rem;
}
.section-title {
    font-family: $fontFamily1;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    margin: 1rem 0rem;
}
.section-input-title{
    font-family: $fontFamily1;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
}
::v-deep.v-input--selection-controls .v-input__slot > .v-label{
  max-width: fit-content !important;
}
::v-deep .v-select.v-select--chips:not(.v-text-field--single-line).v-text-field--enclosed {
    min-height: 50px !important;
}
::v-deep .v-text-field--outlined > .v-input__control > .v-input__slot {
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    min-height: 50px !important;
}
::v-deep .v-text-field--outlined > .v-input__control > .v-input__slot{
   min-height: 50px !important;
}
.equipment-col {
    width: 322px;
}
.equipment-select{
  min-height: 50px;
}
.proficiency-checkbox {
    margin-bottom: 1rem !important;
}
.continue-btn-wrapper{
    max-width: 270px;
    .btn-note {
        margin-top: 0.5rem;
        color: #929292;
        font-family: $fontFamily1;
    }
}
.continue-btn {
    width: 100%;
    border-radius: 1.5rem;
    height: 44px;
    padding: 0.5rem 2rem !important;
    background: $secondary1 !important;
    color: white;
    .v-btn__content {
    font-size: 16px;
    line-height: 28px;
    }
  }
.nav-icon-styles{
  margin-top:20px;
  margin-left:-10px;
}
.backIcon {
  cursor: pointer;
}
.checkboxLabel {
  color: #262626;
}
.divider2{
  margin-bottom: 1rem;
  width:100%;
  border-top:1px solid #DEDEDE;
}
@media screen and (max-width: 600px) {
  .content-main {
   padding-left: 0px;
  }
  .main {
    margin-top: -1rem;
    padding: 2rem;
    width: 100%;
  }
  .nav-icon-styles{
    width:12px;
    height: 16px;
    margin-top:0.5rem;
  }
  .nav-header .text .legend {
    font-size: 30px;
    line-height: 28px;
  }
  .nav-header .text .subtext {
    font-size: 16px;
    line-height: 28px;
  }
  .equipment-col {
    max-width: 322px;
  }
  .rowDisplay {
    display: block;
  }
  .nav-header-icon {
    margin-right: 1rem;
    margin-top: 1rem;
  }
  .main-content {
    margin-left: 1rem;
    margin-bottom:5rem;
  }
  .continue-btn-wrapper{
    max-width: 100%;
    margin-left:0rem;
  }
  .continue-btn{
    width:335px;
    margin-left:0rem !important;
    margin-right:0rem !important;
  }
  .content-main-actions{
    display: flex;
    justify-content: center !important;
  }
  .bs-divider {
    margin-bottom: 1rem;
    border-top: 1px solid #DEDEDE;
  }
  .divider2{
    display: none;
  }
}
</style>
